import Image from "next/image";
import TradeVolume from "./TradeVolume";

const Intro = () => {
  return (
    // <!-- Intro -->
    <section
      id="about"
      className="bg-gradient-to-r from-[transparent_33%] to-[#F5F8FA_33%] py-36 dark:to-[#101436_33%]"
    >
      <div className="container">
        <div className="lg:flex lg:justify-between">
          {/* <!-- Image --> */}
          {/* <div className="relative lg:w-[45%]">
            <figure className="relative">
              <img
                src="/images/dao/intro_dao.jpg"
                className="rounded-2.5xl"
                alt="web protocol"
              />
            </figure>
          </div> */}
          <div className="relative">
            <figure className="flex items-center justify-center">
              <img
                src="/images/dao/benefit_dao_2.jpg"
                alt="benifit"
                className="rounded-full border border-jacarta-100 p-14 dark:border-jacarta-600"
              />
              <img
                src="/images/dao/3d_elements_circle.png"
                alt="benifit"
                className="absolute animate-spin-slow"
              />
            </figure>
          </div>

          {/* <!-- Info --> */}
          <div className="py-10 lg:w-[55%] lg:pl-24">
            <h2 className="mb-6 font-display text-3xl text-jacarta-700 dark:text-white">
              About Me
            </h2>
            <p className="mb-8 text-lg leading-normal dark:text-jacarta-300">
              Hi there! I am Esmael, a proficient React JS developer with over 3
              years of experience in building modern, efficient, and scalable
              web applications. I have the skills and expertise to create
              stunning and user-friendly websites that meet your specific needs
              and goals.
            </p>
            <p className="dark:text-jacarta-300">
              If you are looking for a reliable and skilled React JS developer
              to start a project from scratch or help with an existing one, I am
              here to assist. Whether it’s a simple website or a more complex
              application, I have the trusted React development skills to make
              it happen.
            </p>
            <TradeVolume />
          </div>
        </div>
      </div>
    </section>
    // <!-- end intro -->
  );
};

export default Intro;
