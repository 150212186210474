import HelpBlock from "./HelpBlock";

const index = () => {
  return (
    <section className="relative bg-light-base py-24 dark:bg-jacarta-800">
      <div className="container">
        <div className="items-center justify-between lg:flex lg:space-x-24">
          <div className="lg:w-[45%]">
            <h2 className="mb-6 font-display text-3xl font-medium text-jacarta-700 dark:text-white">
              Why I am the ideal choice for your project:
            </h2>
            <p className="mb-6 text-lg dark:text-jacarta-300">
              {
                "⚡Your success is my success. I prioritize clear communication, on-time delivery, and exceeding your expectations. My track record of client satisfaction speaks for itself."
              }
            </p>
            <p className="dark:text-jacarta-300">
              {
                "⚡If you are looking to start a project from scratch or need help with an existing one, I am here to assist. Whether it's a simple website or a more complex application, I have the trusted React development skills to make it happen.Reach out today, and let's get started on building your amazing website or app!"
              }
            </p>
          </div>
          <div className="lg:w-[55%]">
            <HelpBlock />
          </div>
        </div>
      </div>
    </section>
  );
};

export default index;
