import React from "react";
import Hero_2 from "../../components/hero/hero_2";

import Meta from "../../components/Meta";
import Intro from "../../components/dao/Intro";
import TrustedPartner from "../../components/dao/TrustedPartner";
import Testimonial from "../../components/testimonial/Testimonial";
import NeedHelpBlock from "../../components/crypto/nee-help-block";
import ContactForm from "../../components/contact/ContactForm";
import Address from "../../components/contact/address";
import Services from "../../components/services/services";
import Financialnews from "../../components/blog/financialnews";
import { Bids, NewseLatter } from "../../components/component";
import About_news from "../../components/blog/about_news";

const Home_2 = () => {
  return (
    <>
      <Meta title="Esmael Mohammed" />
      <section id="home" className="hero relative pt-32 pb-32">
        <div className="container ">
          <div className="mx-auto max-w-4xl pt-16 text-center">
            <p className="mb-6 text-lg text-accent dark:text-accent-lighter">
              Web, App Developer and UX/UI Designer
            </p>
            <h1 className="mb-5 font-display text-5xl text-jacarta-700 dark:text-white lg:text-6xl xl:text-7xl">
              {" Hey, I'm Esmael Mohammed "}
              <span className="animate-gradient"> #Every PIXEL Matters!</span>
            </h1>

            <p className="mx-auto mb-8 max-w-md text-lg leading-normal ">
              I create products and companies, Innovation is my passion.
            </p>

            <a
              href="#contact"
              className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
            >
              {"Let's Work Together"}
            </a>
          </div>
        </div>
      </section>
      <Intro />
      <Services />
      <Bids classes="dark:bg-jacarta-800 relative py-24" />
      <TrustedPartner />
      {/* <NewseLatter /> */}
      <NeedHelpBlock />
      <div id="contact" className="container mt-20">
        <div className="lg:flex">
          <div className="mb-12 lg:mb-0 lg:w-2/3 lg:pr-12">
            <h2 className="font-display text-jacarta-700 mb-4 text-xl dark:text-white">
              Contact Us
            </h2>
            <p className="dark:text-jacarta-300 mb-16 text-lg leading-normal">
              {" Have a question? Need help? Don't hesitate, drop us a line"}
            </p>

            <ContactForm />
          </div>

          <Address />
        </div>
      </div>
    </>
  );
};

export default Home_2;
